import React, { FC } from 'react'
import { Dropdown as ANTDDropdown } from 'antd'
import { DropDownStyle } from 'src/@legacy/@core/style/styled/antd/DropDown.style'
import { DropDownProps } from 'antd/lib/dropdown'

export const Dropdown: FC<DropDownProps> = (props) => (
  <>
    <style jsx global>
      {DropDownStyle}
    </style>
    <ANTDDropdown {...props} />
  </>
)
