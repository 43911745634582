import React, { FC } from 'react'
import { Tooltip as ANTDTooltip } from 'antd'
import { TooltipProps } from 'antd/lib/tooltip'
import { TooltipStyle } from 'src/@legacy/@core/style/styled/antd/Tooltip.style'

export const Tooltip: FC<TooltipProps & React.RefAttributes<HTMLDivElement>> = (props) => (
  <>
    <style jsx global>
      {TooltipStyle}
    </style>
    <ANTDTooltip {...props} />
  </>
)
